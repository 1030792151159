var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"ml-auto"},[_c('div',{staticClass:"d-sm-flex mb-4 justify-content-end align-items-center"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione alt+a o option+a'),expression:"'Presione alt+a o option+a'",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center mt-4 mt-sm-0 mr-4",attrs:{"variant":"primary","size":"md","disabled":!_vm.permisos.includes('factura-venta-crear')},on:{"click":_vm.addRegister}},[_c('feather',{staticClass:"mr-2 blue-active-text",attrs:{"type":"plus","size":"1rem"}}),_vm._v(" Nueva Factura ")],1),_c('b-dropdown',{attrs:{"id":"dropdown-divider","text":"Opciones","size":"md","variant":"link","toggle-class":"text-decoration-none","no-caret":"","disabled":!_vm.permisos.includes(
                                'factura-venta-query-export'
                            ) &&
                            !_vm.permisos.includes(
                                'factura-venta-query-export-and-email'
                            ) &&
                            !_vm.permisos.includes(
                                'factura-venta-crud-export'
                            ) &&
                            !_vm.permisos.includes('factura-venta-crud-import')},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather',{staticClass:"text-secondary",attrs:{"type":"more-horizontal","size":"1.5rem"}})],1)]},proxy:true}])},[_vm._l((_vm.formats),function(format,index){return _c('b-dropdown-item-button',{key:index,attrs:{"disabled":!_vm.permisos.includes(
                                    'factura-venta-query-export'
                                )},on:{"click":function($event){return _vm.verifyFields(format.ext)}}},[_vm._v(" "+_vm._s(format.name)+" ")])}),_c('b-dropdown-item-button',{attrs:{"disabled":!_vm.permisos.includes(
                                    'factura-venta-query-export-and-email'
                                )},on:{"click":function($event){return _vm.$bvModal.show('email-modal')}}},[_vm._v("Enviar informe por Correo")])],2)],1)])])]),_c('b-col',{staticClass:"d-flex align-items-stretch",attrs:{"cols":"12","lg":"12"}},[_c('b-card',{staticClass:"mb-4 w-100 h-100"},[_c('div',{staticClass:"mt-5"},[_c('b-form',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}]},[_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"fecha_inicio"}},[_vm._v("Fecha Inicio")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"fecha_inicio","type":"text","placeholder":"AAAA-MM-DD","state":_vm.validateState('fecha_inicio'),"autocomplete":"null","disabled":_vm.isLoading},model:{value:(
                                            _vm.$v.form.fecha_inicio.$model
                                        ),callback:function ($$v) {_vm.$set(_vm.$v.form.fecha_inicio, "$model", $$v)},expression:"\n                                            $v.form.fecha_inicio.$model\n                                        "}}),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"disabled":_vm.isLoading,"button-only":"","right":"","locale":"en-US","state":_vm.validateState(
                                                    'fecha_inicio'
                                                ),"aria-controls":"fecha_inicio"},model:{value:(
                                                _vm.$v.form.fecha_inicio.$model
                                            ),callback:function ($$v) {_vm.$set(_vm.$v.form.fecha_inicio, "$model", $$v)},expression:"\n                                                $v.form.fecha_inicio.$model\n                                            "}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.form.fecha_inicio.formatDate}},[_vm._v(" Formato de fecha inválido ")]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.form.fecha_inicio.compareDates}},[_vm._v(" La fecha inicial no puede ser mayor a la fecha final ")])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"fecha_inicio"}},[_vm._v("Fecha Final")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"fecha_final","type":"text","placeholder":"AAAA-MM-DD","state":_vm.validateState('fecha_final'),"disabled":_vm.isLoading,"autocomplete":"null"},model:{value:(_vm.$v.form.fecha_final.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.fecha_final, "$model", $$v)},expression:"$v.form.fecha_final.$model"}}),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","right":"","locale":"en-US","disabled":_vm.isLoading,"state":_vm.validateState('fecha_final'),"aria-controls":"fecha_final"},model:{value:(
                                                _vm.$v.form.fecha_final.$model
                                            ),callback:function ($$v) {_vm.$set(_vm.$v.form.fecha_final, "$model", $$v)},expression:"\n                                                $v.form.fecha_final.$model\n                                            "}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.form.fecha_final.formatDate}},[_vm._v(" Formato de fecha inválido ")]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.form.fecha_final.compareDates}},[_vm._v(" La fecha inicial no puede ser mayor a la fecha final ")])],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('label',{attrs:{"for":"prefijo"}},[_vm._v("Prefijo")]),_c('jautocomplete',{attrs:{"id":"prefijo","items":_vm.consecutivos,"item-value":"prefijo","item-text":"prefijo","open-on-focus":"","disabled":_vm.isLoading},model:{value:(_vm.$v.form.prefijo_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.prefijo_id, "$model", $$v)},expression:"$v.form.prefijo_id.$model"}})],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"filter-input"}},[_vm._v("Texto adicional para buscar")]),_c('b-form-input',{attrs:{"disabled":_vm.isLoading,"id":"filter-input","type":"text","placeholder":"Escriba algún texto para buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('div',{staticClass:"float-right mb-5"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                'Presione ctrl+b o cmd+b'
                            ),expression:"\n                                'Presione ctrl+b o cmd+b'\n                            ",modifiers:{"hover":true,"top":true}}],staticClass:"mt-4 d-flex align-items-center",attrs:{"variant":"primary","size":"md","disabled":_vm.isLoading},on:{"click":function($event){return _vm.getFacturasVenta()}}},[(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Cargando...")])],1):_vm._e(),(!_vm.isLoading)?_c('feather',{staticClass:"text-blue-active mr-2",attrs:{"type":"search","size":"1rem"}}):_vm._e(),(!_vm.isLoading)?_c('span',[_vm._v("Consultar")]):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"table-responsive mt-3"},[_c('b-table',{staticClass:"mb-0 hover-table text-nowrap",attrs:{"fields":_vm.fields,"items":_vm.facturas_venta,"tbody-tr-class":_vm.rowClass,"borderless":"","responsive":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(prefijo)",fn:function(data){return [_c('span',{class:`badge bg-light-success ${
                                _vm.Theme === 'dark' && 'text-gray'
                            }`},[_c('feather',{staticClass:"mr-1",attrs:{"type":"hash","size":"0.8rem"}}),_vm._v(" "+_vm._s(data.item.consecutivo.prefijo || 'Sin prefijo')+_vm._s(data.item.numero)+" ")],1)]}},{key:"cell(doc_fe)",fn:function(data){return [_c('span',{class:`text-${_vm.getStatusColor(data.value.estadoDocumento?.codigo)}`},[_vm._v(" "+_vm._s(Object.keys(data.value).length > 0 ? data.value.estadoDocumento?.codigo || 'SIN ESTADO' : 'NO')+" ")])]}},{key:"cell(cliente)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.value.codigo)+" - "+_vm._s(data.value.nombre_comercial)+" ")])]}},{key:"cell(cliente_id)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.cliente.tercero.n_documento)+" - "+_vm._s(_vm.getLabelTercero(data.item.cliente.tercero))+" ")])]}},{key:"cell(gran_total)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormat(data.value.toFixed(2)))+" ")])]}},{key:"cell(acciones)",fn:function(data){return [_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Ver'),expression:"'Ver'",modifiers:{"hover":true,"top":true}}],attrs:{"type":"eye","role":"button"},on:{"click":function($event){return _vm.$router.push({
                                    name: 'Ver Factura de venta',
                                    params: {
                                        factura_id: data.item.id
                                    }
                                })}}}),(!_vm.isLoading)?_c('span',[(
                                    (data.item.doc_fe.estadoDocumento !==
                                        undefined &&
                                        (data.item.doc_fe.estadoDocumento
                                            .codigo === 'DEFAULT' ||
                                            data.item.doc_fe.estadoDocumento
                                                .codigo === 'RECHAZADO')) ||
                                    data.item.doc_fe.reenviar
                                )?_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                    'Reenviar factura electrónica'
                                ),expression:"\n                                    'Reenviar factura electrónica'\n                                ",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2",attrs:{"type":"refresh-cw","role":"button"},on:{"click":function($event){return _vm.confirmResendFactura({
                                        id: data.item.id,
                                        numero: `${data.item.consecutivo.prefijo}${data.item.numero}`
                                    })}}}):_vm._e()],1):_c('span',[((data.item.doc_fe.estadoDocumento !==
                                        undefined &&
                                        (data.item.doc_fe.estadoDocumento
                                            .codigo === 'DEFAULT' ||
                                            data.item.doc_fe.estadoDocumento
                                                .codigo === 'RECHAZADO')) ||
                                    data.item.doc_fe.reenviar
                                    )?_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Reenviar factura electrónica'
                                    ),expression:"'Reenviar factura electrónica'\n                                    ",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2 text-secondary",attrs:{"type":"refresh-cw","role":"button"}}):_vm._e()],1),(
                                _vm.permisos.includes(
                                    'factura-venta-export-individual-and-email'
                                ) && !_vm.isLoading && !((data.item.doc_fe.estadoDocumento !==
                                undefined &&
                                (data.item.doc_fe.estadoDocumento
                                    .codigo === 'DEFAULT' ||
                                    data.item.doc_fe.estadoDocumento
                                        .codigo === 'RECHAZADO')) ||
                                data.item.doc_fe.reenviar)
                            )?_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Enviar por correo'),expression:"'Enviar por correo'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2",attrs:{"type":"mail","role":"button"},on:{"click":function($event){return _vm.sendSingleFactura(data.item.id)}}}):_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Enviar por correo'),expression:"'Enviar por correo'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2 text-secondary",attrs:{"type":"mail","role":"button"}}),(
                                _vm.permisos.includes(
                                    'factura-venta-export-individual'
                                ) && !_vm.isLoading && !((data.item.doc_fe.estadoDocumento !==
                                undefined &&
                                (data.item.doc_fe.estadoDocumento
                                    .codigo === 'DEFAULT' ||
                                    data.item.doc_fe.estadoDocumento
                                        .codigo === 'RECHAZADO')) ||
                                data.item.doc_fe.reenviar)
                            )?_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Imprimir'),expression:"'Imprimir'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2",attrs:{"type":"printer","role":"button"},on:{"click":function($event){return _vm.printFactura(data.item.id)}}}):_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Imprimir'),expression:"'Imprimir'",modifiers:{"hover":true,"top":true}}],staticClass:"text-secondary ml-2",attrs:{"type":"printer","role":"button"}}),(!_vm.isLoading)?_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Devolución de factura'),expression:"'Devolución de factura'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2",attrs:{"type":"repeat","role":"button"},on:{"click":function($event){return _vm.$router.push({
                                    name: 'Crear devolucion de factura',
                                    params: {
                                        factura_venta_id: data.item.id
                                    }
                                })}}}):_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Devolución de factura'),expression:"'Devolución de factura'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2 text-secondary",attrs:{"type":"repeat","role":"button"}})]}}])})],1),_c('div',{staticClass:"p-3 d-flex align-items-center"},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Número de registros","label-for":"per-page-select","label-size":"sm","label-class":"fw-medium"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pagination.pageOptions,"size":"sm"},on:{"change":function($event){return _vm.getFacturasVenta(1, $event)}},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}})],1),_c('div',{staticClass:"ml-auto"},[_c('b-pagination',{staticClass:"my-0",attrs:{"first-number":"","last-number":"","size":"sm","total-rows":_vm.pagination.totalRows,"per-page":_vm.pagination.perPage,"align":"fill"},on:{"input":function($event){return _vm.getFacturasVenta($event, _vm.pagination.perPage)}},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],1)]),_c('selectFieldsReport',{attrs:{"modulo":_vm.moduleName,"format":_vm.selectedFormat,"fields":_vm.fieldsAvailables[
                    _vm.selectedFormat ? _vm.selectedFormat.toLowerCase() : 'excel'
                ]},on:{"close":function($event){_vm.selectedFormat = null}}}),_c('emailModal',{attrs:{"sender":_vm.configEmail,"modulo":_vm.firstToUpperString(_vm.moduleName),"fieldsAvailables":_vm.fieldsAvailables}}),_c('emailModal',{attrs:{"id":'email-modal-single',"sender":_vm.configEmail,"modulo":_vm.firstToUpperString(_vm.moduleName),"fieldsAvailables":_vm.fieldsAvailables,"url":'administracion/factura-venta/enviar-mail',"alternativeOpc":'FIND_BY_ID',"extraParamsDatajson":_vm.singleEmailParams}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-sm-flex mt-4 justify-content-between align-items-center"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione alt+a o option+a'),expression:"'Presione alt+a o option+a'",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center mt-4 mt-sm-0 mr-4",attrs:{"variant":"primary","size":"md","disabled":!_vm.permisos.includes('factura-venta-crear')},on:{"click":_vm.addRegister}},[_c('feather',{staticClass:"mr-2 blue-active-text",attrs:{"type":"plus","size":"1rem"}}),_vm._v(" Nueva Factura ")],1),_c('b-dropdown',{attrs:{"id":"dropdown-divider","text":"Opciones","size":"md","variant":"link","toggle-class":"text-decoration-none","no-caret":"","disabled":!_vm.permisos.includes('factura-venta-query-export') &&
                    !_vm.permisos.includes(
                        'factura-venta-query-export-and-email'
                    ) &&
                    !_vm.permisos.includes('factura-venta-crud-export') &&
                    !_vm.permisos.includes('factura-venta-crud-import')},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather',{staticClass:"text-secondary",attrs:{"type":"more-horizontal","size":"1.5rem"}})],1)]},proxy:true}])},[_vm._l((_vm.formats),function(format,index){return _c('b-dropdown-item-button',{key:index,attrs:{"disabled":!_vm.permisos.includes('factura-venta-query-export')},on:{"click":function($event){return _vm.verifyFields(format.ext)}}},[_vm._v(" "+_vm._s(format.name)+" ")])}),_c('b-dropdown-item-button',{attrs:{"disabled":!_vm.permisos.includes(
                            'factura-venta-query-export-and-email'
                        )},on:{"click":function($event){return _vm.$bvModal.show('email-modal')}}},[_vm._v("Enviar informe por Correo")])],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }